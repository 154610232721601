import { GraphQLFormattedError } from "graphql";
import { extraRequestHeaders } from "util/browser";

export class ClientGraphqlError extends Error {
    constructor(public readonly graphqlErrors: GraphQLFormattedError[]) {
        super(ClientGraphqlError.getFirstErrorMessage(graphqlErrors));

        this.hasErrorType = (errorType: string) => {
            return this.graphqlErrors.some(
                e => e.extensions && e.extensions.errorType === errorType,
            );
        };
    }

    public hasErrorType = (errorType: string): boolean => {
        return this.graphqlErrors.some(
            e => e.extensions && e.extensions.errorType === errorType,
        );
    };

    public hasMessageContaining = (needle: string): boolean => {
        return this.graphqlErrors.some(e => e.message.includes(needle));
    };

    static getFirstErrorMessage(graphqlErrors: GraphQLFormattedError[]) {
        if (graphqlErrors && graphqlErrors.length > 0) {
            return graphqlErrors[0].message;
        }

        return "An unknown error occurred.";
    }
}

export function graphqlFetcher<TData, TVariables>(query: string, variables?: TVariables) {
    return async (): Promise<TData> => {
        const res = await fetch("/api/graphql" as string, {
            method: "POST",
            ...{
                headers: { ...extraRequestHeaders(), "content-type": "application/json" },
            },
            body: JSON.stringify({ query, variables }),
        });

        const json = await res.json();

        if (json.errors) {
            throw new ClientGraphqlError(json.errors);
        }

        if (!res.ok) {
            throw new ClientGraphqlError([
                {
                    message: "An unknown error occurred.",
                    locations: [],
                },
            ]);
        }

        return json.data;
    };
}
