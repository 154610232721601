import { ActionType, Maybe } from "@sp-crm/core";
import * as _ from "lodash";
import { PermissionsState } from "../store/reducers/permissions";
import { RegionState } from "../store/reducers/region";

export function isAllowed(
    action: ActionType,
    permissions: PermissionsState,
    regions: RegionState,
): boolean {
    const permittedRegions = Maybe.fromValue(permissions)
        .map(p => p.permissions)
        .map(p => p.regions)
        .getOrElse([]);
    const allowedActions = Maybe.fromValue(regions)
        .map(r => r.selectedRegion)
        .map(r => _.find(permittedRegions, p => p.regionKey === r))
        .map(p => p.permissions)
        .getOrElse([]);
    return allowedActions.indexOf(action) >= 0;
}

export function allowedRegionsForAction(
    action: ActionType,
    permissions: PermissionsState,
): string[] {
    return Maybe.fromValue(permissions)
        .map(p => p.permissions)
        .map(p => p.regions)
        .map(p => p.filter(r => r.permissions.indexOf(action) >= 0))
        .map(p => p.map(r => r.regionKey))
        .getOrElse([]);
}
