import { InternalId } from "@sp-crm/core";
import { RequestState } from "../constants/loading";

export interface RequestDescription {
    started: Date;
    ended: Date;
    status: RequestState;
    requestId?: InternalId;
    correlationId?: InternalId;
    details?: string;
    eventType?: string;
    data?: unknown;
}

export const requestSummary = (desc: RequestDescription) => {
    return `${desc.correlationId}, ${desc.requestId}, ${desc.details}`;
};
