import { locales, Localization, Maybe } from "@sp-crm/core";
import { createSelector } from "reselect";
import { RegionState } from "../reducers/region";
import { getRegion } from "./selectors";

export const usesMoreThanOneRegion = createSelector(getRegion, (region: RegionState) => {
    const { regions } = region;
    return regions.length > 1;
});

export const currentLocale = createSelector(
    getRegion,
    (region: RegionState): Localization => {
        const localeName = Maybe.fromValue(region.selectedRegion)
            .map(key => region.regions.find(r => r.key === key))
            .map(r => r.locale)
            .getOrElse("default");
        return locales[localeName] || locales.default;
    },
);
