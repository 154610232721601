import { TenantId, UserId } from "@sp-crm/core";
import posthog from "posthog-js";

let isConfigured = false;
let pendingCallbacks: Array<() => void> = [
    () => {
        posthog.reset();
    },
];

export const configureUserEvents = async () => {
    posthog.init("phc_d7GRBC0geaW6GXnp3Yzincyb8gEf6ibDeC5hESoeWBF", {
        api_host: "https://app.posthog.com",
        autocapture: false,
        loaded: () => {
            isConfigured = true;
            pendingCallbacks.forEach(callback => callback());
            pendingCallbacks = [];
        },
    });
};

const run = (cb: () => void) => {
    if (isConfigured) {
        cb();
    } else {
        pendingCallbacks.push(cb);
    }
};

interface Credentials {
    tenantId?: TenantId;
    userId?: UserId;
}

let credentials = {} as Credentials;

const handleCredentials = () => {
    if (credentials.tenantId && credentials.userId) {
        run(() => {
            posthog.identify(credentials.userId, {
                tenantId: credentials.tenantId,
            });
        });
    }
};

const userEvent = [
    "community-search-advanced-filter",
    "open-feature-announcements",
    "community-missing-recipients",
    "client-action",
    "create-client",
    "client-status-change",
    "task-create",
    "task-complete",
    "message-send",
    "premium-callout",
] as const;
type UserEvent = typeof userEvent[number];

export const handleEvent = (
    name: UserEvent,
    properties: Record<string, unknown> = {},
) => {
    run(() => {
        posthog.capture(name, properties);
    });
};

export const tenantIdEvent = (tenantId: TenantId) => {
    credentials.tenantId = tenantId;
    handleCredentials();
};

export const userIdEvent = (userId: UserId) => {
    credentials.userId = userId;
    handleCredentials();
};

export const logoutEvent = () => {
    credentials = {};
    run(() => {
        posthog.reset();
    });
};
