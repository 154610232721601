import { InternalId } from "@sp-crm/core";
import { Action } from "redux";
import { RequestState } from "../../constants/loading";
import { RequestDescription } from "../../types/request-description";
import { Actions } from "./index";

// TODO this should be called requests, not types

export interface RequestAction extends Action {
    eventId: InternalId;
    when: Date;
    data?: unknown;
}

export function makeRequestAction(action: Actions, eventId: InternalId, data?: unknown) {
    const result: RequestAction = {
        eventId,
        type: Actions[action],
        when: new Date(),
        data,
    };
    return result;
}

export interface RequestFailAction extends Action {
    started: Date;
    ended: Date;
    requestId: InternalId;
    correlationId: InternalId;
    details: string;
    status: RequestState;
}

export function makeRequestFailAction(
    requestDescription: RequestDescription,
): RequestFailAction {
    return {
        requestId: requestDescription.requestId,
        started: requestDescription.started,
        ended: requestDescription.ended || new Date(),
        details: requestDescription.details,
        correlationId: requestDescription.correlationId,
        status: requestDescription.status,
        type: Actions[Actions.REQUEST_FAIL],
    };
}

export function makeRequestUnauthorizedAction(
    requestDescription: RequestDescription,
): RequestFailAction {
    return {
        requestId: requestDescription.requestId,
        started: requestDescription.started,
        ended: requestDescription.ended || new Date(),
        details: requestDescription.details,
        correlationId: requestDescription.correlationId,
        status: requestDescription.status,
        type: Actions[Actions.REQUEST_UNAUTHORIZED],
    };
}

export interface RequestFailAcknowledgeAction extends Action {
    eventId: InternalId;
}

export function makeRequestFailAcknowledgeAction(eventId: InternalId) {
    return {
        eventId,
        type: Actions[Actions.REQUEST_FAIL_ACKNOWLEDGE],
    };
}

export function makeRequestUnauthorizedAcknowledgeAction(eventId: InternalId) {
    return {
        eventId,
        type: Actions[Actions.REQUEST_UNAUTHORIZED_ACKNOWLEDGE],
    };
}
