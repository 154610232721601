import { genInternalId } from "@sp-crm/core";
import axios, { AxiosPromise, AxiosResponse } from "axios";
import { fancyAlert } from "components/ui/fancy-alert";
import Debug from "debug";
import { RequestState } from "../constants/loading";
import { Actions } from "../store/actions";
import {
    makeRequestFailAction,
    makeRequestUnauthorizedAction,
} from "../store/actions/types";
import { RequestDescription, requestSummary } from "../types/request-description";
import { extraRequestHeaders } from "./browser";
import { reportError } from "./error-tracking";
import { push } from "./history";

const debug = Debug("placement-crm.http");

export function send(
    requestDescription: RequestDescription,
    method: string,
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    body?: any,
): AxiosPromise {
    const options = {
        url: path,
        method,
        headers: {
            ...extraRequestHeaders(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-crm-client-correlation-id": requestDescription.requestId,
        },
    };
    if (body) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        (options as any)["data"] = body;
    }
    const promise = axios(options);
    return promise;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function get<T = any>(
    requestDescription: RequestDescription,
    path: string,
): AxiosPromise<T> {
    const promise = axios({
        url: path,
        method: "GET",
        headers: {
            ...extraRequestHeaders(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-crm-client-correlation-id": requestDescription.requestId,
        },
    });
    return promise;
}

export function getBlob(
    requestDescription: RequestDescription,
    path: string,
    params?: { [key: string]: string },
): AxiosPromise {
    return axios({
        url: path,
        method: "GET",
        headers: {
            ...extraRequestHeaders(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-crm-client-correlation-id": requestDescription.requestId,
        },
        responseType: "blob",
        params: params,
    });
}

export function post(
    requestDescription: RequestDescription,
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    body: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    headers: any = {},
): AxiosPromise {
    const options = {
        url: path,
        method: "POST",
        headers: {
            ...extraRequestHeaders(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-crm-client-correlation-id": requestDescription.requestId,

            ...headers,
        },
        data: body,
    };
    const promise = axios(options);
    return promise;
}

export function deleteReq(
    requestDescription: RequestDescription,
    path: string,
): AxiosPromise {
    const options = {
        method: "DELETE",
        headers: {
            ...extraRequestHeaders(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-crm-client-correlation-id": requestDescription.requestId,
        },
        url: path,
    };
    const promise = axios(options);
    return promise;
}

export function put(
    requestDescription: RequestDescription,
    path: string,
    body: unknown,
): AxiosPromise {
    const options = {
        method: "PUT",
        headers: {
            ...extraRequestHeaders(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-crm-client-correlation-id": requestDescription.requestId,
        },
        data: body,
        url: path,
    };
    const promise = axios(options);
    return promise;
}

class ClientRequestError extends Error {
    readonly name = "ClientRequestError";
}

export function errorHandler(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    requestOrError: any,
    requestDescription: RequestDescription,
    // eslint-disable-next-line @typescript-eslint/ban-types -- eslintintroduction
    dispatch: Function,
): void {
    if (
        requestOrError &&
        requestOrError.request &&
        requestOrError.message === "Network Error"
    ) {
        // 'Network Error' comes from https://github.com/axios/axios/blob/v1.x/lib/adapters/xhr.js#L151
        // TODO upgrade axios to get a better type on the exception
        dispatch(
            makeRequestFailAction(
                failedRequestDescription(requestDescription, {
                    msg: "Network error",
                    correlationId: "Network error",
                }),
            ),
        );
    } else if (requestOrError && requestOrError.response) {
        const response: AxiosResponse = requestOrError.response;
        if (response.status >= 500) {
            const payload = failedRequestDescription(
                requestDescription,
                requestOrError.response.data,
            );
            if (response.data.customError) {
                fancyAlert("Error", response.data.customError);
            } else {
                dispatch(makeRequestFailAction(payload));
                debug("errorHandler 500 %o", requestOrError.response);
                let exception: Error;
                try {
                    throw new ClientRequestError(requestSummary(payload));
                } catch (e) {
                    exception = e;
                }
                reportError(exception, payload);
            }
        } else if (response.status === 404) {
            fancyAlert(
                "Record not found",
                'Sorry, the record you are accessing does not exist (or it was deleted). Try clicking "back" or close this tab.',
            );
        } else if (response.status === 422) {
            fancyAlert(
                "Please review your data",
                "Sorry, we weren't able to understand the last update. Please try updating again.",
            );
        } else if (response.status === 403) {
            const payload = failedRequestDescription(
                requestDescription,
                requestOrError.response.data,
            );
            dispatch(makeRequestUnauthorizedAction(payload));
        } else if (response.status === 401) {
            debug("errorHandler 401 %o", requestOrError.response);
            dispatch({ type: Actions[Actions.LOGOUT] });
            push("/login");
        } else {
            debug("errorHandler !500,!401 %o", requestOrError.response);
        }
    } else {
        // not a request, throw!
        throw requestOrError;
    }
}

export function newRequestDescription() {
    const eventId = genInternalId();
    const requestDescription: RequestDescription = {
        started: new Date(),
        ended: null,
        status: RequestState.NOTSTARTED,
        requestId: eventId,
    };
    return requestDescription;
}

export function failedRequestDescription(
    newRequestDescription: RequestDescription,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    responseData: any,
) {
    const requestDescription: RequestDescription = {
        ...newRequestDescription,
        ended: new Date(),
        status: RequestState.ERROR,
        correlationId: responseData.correlationId,
        details: responseData.msg,
        eventType: "failedRequest",
    };
    return requestDescription;
}
